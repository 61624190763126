@font-face {
  font-family: "Bronova";
  font-weight: 400;
  src: url(../fonts/bronova/Bronova-Regular.ttf);
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url(../fonts/poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url(../fonts/poppins/Poppins-Bold.ttf);
}

html {
  background-color: #f2f2f5;
}

:root {
  --hidden-layer: -9999999;
  --background-layer: 9;
  --placeholder-layer: 99;
  --navigation-layer: 999;
  --modal-first-layer: 9999;
  --modal-second-layer: 99999;
  --snackbar-layer: 999999;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
